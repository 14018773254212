
import { computed, defineComponent, onMounted, Ref, ref } from 'vue';
import HeadingComponent from '@/components/HeadingComponent.vue';
import LoadingComponent from '@/components/LoadingComponent.vue';
import { medicalTestService } from '@/services/MedicalTestService';
import { useRoute } from 'vue-router';
import { useI18n } from 'vue-i18n';
import dateFormat from '@/helpers/DateFormat.helper';
import { measurementService } from '@/services/MeasurementService';
import Statistics from '@/models/Statistics';
import { MedicalTest } from '@/models/MedicalTest';
import { PatientDto } from '@/models/Patient';
import { patientService } from '@/services/PatientService';
import CardiometabolicChart from '@/components/charts/CardiometabolicChart.vue';
import RestingMetabolismChart from '@/components/charts/RestingMetabolismChart.vue';
import PhasesChart from '@/components/charts/PhasesChart.vue';
import HeartRateChart from '@/components/charts/HeartRateChart.vue';
import CalorimetryForm from '@/components/CalorimetryForm.vue';
import MetabolicScoresChart from './charts/MetabolicScoresChart.vue';
import ProfessionalValuationCard from '@/components/charts/ProfessionalValuationCard.vue';
import { ServiceError } from '@/services/util/ServiceError';

export default defineComponent({
  components: {
    HeadingComponent,
    LoadingComponent,
    ProfessionalValuationCard,
    CardiometabolicChart,
    RestingMetabolismChart,
    PhasesChart,
    HeartRateChart,
    CalorimetryForm,
    MetabolicScoresChart,
  },

  setup() {
    const { t } = useI18n();
    const route = useRoute();
    const medicalTest: Ref<MedicalTest | undefined> = ref();
    const modal = ref(false);
    const breadcrumb = ref([] as Array<any>);
    const medicalTestDetails = ref([] as Array<any>);
    const statistics: Ref<Statistics | undefined> = ref();
    const patientId = Array.isArray(route.params.patientId) ? route.params.patientId[0] : route.params.patientId;
    const medicalTestId = Array.isArray(route.params.medicalTest)
      ? route.params.medicalTest[0]
      : route.params.medicalTest;
    const routeBreadcrumb = route.meta.breadcrumb as any[];
    const patient: Ref<PatientDto | undefined> = ref();
    const loadingStatistics = ref(true);
    const waitingForReport = ref(false);
    const CalorimetryFormRef = ref();
    const uploading = ref(false);

    const downloadIconClass = computed(() => {
      return {
        'icon-doughnut_chart icon-spin': waitingForReport.value === true,
        'icon-download': waitingForReport.value === false,
      };
    });

    onMounted(async () => {
      await loadMedicalTestData();
      await loadPatientData();
      await loadStatistics();
    });

    async function loadMedicalTestData() {
      const result = await medicalTestService.find(patientId, medicalTestId);
      if (!(result instanceof ServiceError)) {
        medicalTest.value = Object.assign({}, result);
        breadcrumb.value = [
          { label: t(routeBreadcrumb[0].parent), to: `/patient/${patientId}/medical-tests` },
          { label: medicalTest.value.name, disabled: true },
        ];
        medicalTestDetails.value = [
          {
            icon: 'icon-calendar',
            iconLabel: t('details.dateAndHour'),
            label: dateFormat.formatDateAndHour(dateFormat.formatLocalTimezone(medicalTest.value.date)),
          },
        ];
      }
    }
    async function loadPatientData() {
      const result = await patientService.find(patientId);
      if (!(result instanceof ServiceError)) {
        patient.value = result;
        if (medicalTest.value && result.gender) {
          medicalTest.value.gender = result.gender;
        }
      }
    }

    async function loadStatistics() {
      loadingStatistics.value = true;
      const result = await measurementService.findStatistics(patientId, medicalTestId);
      if (result && result.charts) {
        medicalTestDetails.value.push({
          icon: 'icon-show',
          label: t('details.initialData'),
          clicable: true,
          command: () => {
            modal.value = true;
          },
        });
        statistics.value = result;
      }
      loadingStatistics.value = false;
    }

    const onDownloadReportClicked = async () => {
      waitingForReport.value = true;
      const result = await measurementService.getPdfFile(patientId, medicalTestId);
      waitingForReport.value = false;

      downloadFile(result?.file);
    };

    const downloadFile = (fileBase64: string) => {
      const a = document.createElement('a'); //Create <a>
      a.href = 'data:application/pdf;base64,' + fileBase64; //File Base64 Goes here
      a.download = medicalTest.value?.name ? medicalTest.value?.name : 'Report' + '.pdf'; //File name Here
      a.click(); //Downloaded file
    };

    const onSubmit = async () => {
      uploading.value = true;
      await CalorimetryFormRef.value.submit();
      uploading.value = false;
    };

    const onFormSubmitted = async () => {
      uploading.value = false;
      await loadStatistics();
    };

    return {
      modal,
      medicalTest,
      medicalTestDetails,
      breadcrumb,
      statistics,
      patient,
      uploading,
      loadingStatistics,
      waitingForReport,
      CalorimetryFormRef,
      downloadIconClass,
      onDownloadReportClicked,
      onSubmit,
      onFormSubmitted,
    };
  },
});
