
import { getColorInRange } from '@/helpers/Rainbow.helper';
import { Score, ScoreType } from '@/models/Score';
import { ComputedRef, defineComponent } from '@vue/runtime-core';
import { computed } from 'vue';

export default defineComponent({
  props: {
    metabolicScore: {
      type: Object as () => Score,
      required: true,
    },
  },
  setup(props) {
    const percentageValue = computed(() => {
      const rangeStart = props.metabolicScore.optimalValueMin || 0;
      const rangeEnd = props.metabolicScore.optimalValueMax || 10;
      let raw = props.metabolicScore.value
        ? ((props.metabolicScore.value - rangeStart) / (rangeEnd - rangeStart)) * 100
        : 0;

      if (raw < 0) {
        raw = 0;
      }
      if (raw > 100) {
        raw = 100;
      }

      return raw;
    });

    const scoreScale = computed(() => {
      switch (props.metabolicScore.type) {
        case ScoreType.FAT_OXIDATION_RESTING:
          return 'red-green';
        case ScoreType.TOTAL_ENERGY_RESTING:
        case ScoreType.FAT_OXIDATION_ACTIVITY:
          return 'red-green-yellow';
        default:
          return 'red-green-red';
      }
    });

    const pointerColor: ComputedRef<string> = computed(() => {
      let range = [];
      switch (scoreScale.value) {
        case 'red-green':
          range = ['C90000', 'C90000', '00B833', '00B833', '00B833']; // intended. This produces realistic colors for the fat oxidation resting bar
          break;
        case 'red-green-yellow':
          range = ['C90000', 'FFD15B', '00B833', '00B833', 'FFD15B'];
          break;
        default:
          range = ['C90000', 'FFD15B', '00B833', 'FFD15B', 'C90000'];
      }
      const color = getColorInRange(percentageValue.value, range);
      return `#${color}`;
    });

    const leftStyle = computed(() => `calc(${percentageValue.value}% - 2.5px)`);

    return { percentageValue, leftStyle, scoreScale, pointerColor };
  },
});
