
import { defineComponent, onMounted, ref } from 'vue';
import HeadingComponent from '@/components/HeadingComponent.vue';
import { medicalTestService } from '@/services/MedicalTestService';
import { useToast } from 'primevue/usetoast';
import { useI18n } from 'vue-i18n';
import { ServiceError } from '@/services/util/ServiceError';

export default defineComponent({
  components: { HeadingComponent },
  emits: ['button-click'],
  props: {
    medicalTest: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const { t } = useI18n();
    const toast = useToast();

    const waitSubmit = ref(false);
    const editMode = ref(false);
    const activeIndex = ref(0);
    const savedObservations = ref();
    const savedActionsToBeTacken = ref();
    const observations = ref();
    const actionsToBeTaken = ref();

    onMounted(() => {
      savedObservations.value = props.medicalTest.observations;
      savedActionsToBeTacken.value = props.medicalTest.actionsToBeTaken;
      observations.value = props.medicalTest.observations;
      actionsToBeTaken.value = props.medicalTest.actionsToBeTaken;
    });

    const onEditClicked = () => {
      editMode.value = true;
    };

    const onCancelObservations = () => {
      editMode.value = false;
      observations.value = savedObservations.value;
    };
    const onCancelActions = () => {
      editMode.value = false;
      actionsToBeTaken.value = savedActionsToBeTacken.value;
    };

    const onSubmitObservations = async () => {
      waitSubmit.value = true;
      const result = await medicalTestService.addObservations(
        props.medicalTest.patientId,
        props.medicalTest.id,
        observations.value,
      );
      waitSubmit.value = false;
      if (result instanceof ServiceError) {
        toast.add({
          severity: 'error',
          summary: `${t('messages.notifications.errorEditObservations')} ${t('messages.notifications.tryLater')}`,
          life: 3000,
        });
      } else {
        savedObservations.value = result.observations;
        editMode.value = false;
        toast.add({
          severity: 'success',
          summary: `${t('messages.notifications.successEditObservations')}`,
          life: 3000,
        });
      }
    };

    const onSubmitActionsToBeTaken = async () => {
      waitSubmit.value = true;
      const result = await medicalTestService.addActionsToBeTaken(
        props.medicalTest.patientId,
        props.medicalTest.id,
        actionsToBeTaken.value,
      );
      waitSubmit.value = false;
      if (result instanceof ServiceError) {
        toast.add({
          severity: 'error',
          summary: `${t('messages.notifications.errorEditActionsToBeTaken')} ${t('messages.notifications.tryLater')}`,
          life: 3000,
        });
      } else {
        savedActionsToBeTacken.value = result.actionsToBeTaken;
        editMode.value = false;
        toast.add({
          severity: 'success',
          summary: `${t('messages.notifications.successEditActionsToBeTaken')}`,
          life: 3000,
        });
      }
    };

    return {
      waitSubmit,
      activeIndex,
      editMode,
      observations,
      actionsToBeTaken,
      savedObservations,
      savedActionsToBeTacken,
      onSubmitObservations,
      onSubmitActionsToBeTaken,
      onCancelObservations,
      onCancelActions,
      onEditClicked,
    };
  },
});
