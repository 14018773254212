
import { defineComponent } from '@vue/runtime-core';
import MetabolicScoreWidget from '@/components/charts/MetabolicScore.vue';
import MetabolicScoreResult from '@/components/charts/MetabolicScoreResult.vue';
import MetabolicScoreEfficiency from '@/components/charts/MetabolicScoreEfficiency.vue';
import { Score, ScoreType } from '@/models/Score';

export default defineComponent({
  components: {
    MetabolicScoreWidget,
    MetabolicScoreResult,
    MetabolicScoreEfficiency,
  },
  props: {
    scores: {
      type: Array as () => Score[],
      required: true,
    },
  },
  setup(props) {
    const totalEnergyActivity: Score | null =
      props.scores.find((score) => score.type === ScoreType.TOTAL_ENERGY_ACTIVITY) || null;

    const totalEnergyResting: Score | null =
      props.scores.find((score) => score.type === ScoreType.TOTAL_ENERGY_RESTING) || null;

    const fatOxidationActivity: Score | null =
      props.scores.find((score) => score.type === ScoreType.FAT_OXIDATION_ACTIVITY) || null;

    const fatOxidationResting: Score | null =
      props.scores.find((score) => score.type === ScoreType.FAT_OXIDATION_RESTING) || null;

    const efficiency: Score | null = props.scores.find((score) => score.type === ScoreType.EFFICIENCY) || null;

    const efficiencyValue = efficiency?.value;
    return {
      totalEnergyActivity,
      totalEnergyResting,
      fatOxidationActivity,
      fatOxidationResting,
      efficiencyValue,
    };
  },
});
