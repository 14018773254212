import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "metabolic-scores-chart" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MetabolicScoreEfficiency = _resolveComponent("MetabolicScoreEfficiency")!
  const _component_MetabolicScoreResult = _resolveComponent("MetabolicScoreResult")!
  const _component_MetabolicScoreWidget = _resolveComponent("MetabolicScoreWidget")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_MetabolicScoreEfficiency, { "metabolic-score-value": _ctx.efficiencyValue }, null, 8, ["metabolic-score-value"]),
    _createVNode(_component_MetabolicScoreWidget, {
      class: "metabolic-scores-chart__widget",
      title: _ctx.$t('metabolicEvaluation.totalEnergyExpenditure')
    }, {
      first: _withCtx(() => [
        _createVNode(_component_MetabolicScoreResult, {
          title: _ctx.$t('metabolicEvaluation.physicalActivity'),
          "metabolic-score": _ctx.totalEnergyActivity
        }, null, 8, ["title", "metabolic-score"])
      ]),
      second: _withCtx(() => [
        _createVNode(_component_MetabolicScoreResult, {
          title: _ctx.$t('metabolicEvaluation.rest'),
          "metabolic-score": _ctx.totalEnergyResting
        }, null, 8, ["title", "metabolic-score"])
      ]),
      _: 1
    }, 8, ["title"]),
    _createVNode(_component_MetabolicScoreWidget, {
      class: "metabolic-scores-chart__widget",
      title: _ctx.$t('metabolicEvaluation.fatOxidation')
    }, {
      first: _withCtx(() => [
        _createVNode(_component_MetabolicScoreResult, {
          title: _ctx.$t('metabolicEvaluation.movement'),
          "metabolic-score": _ctx.fatOxidationActivity
        }, null, 8, ["title", "metabolic-score"])
      ]),
      second: _withCtx(() => [
        _createVNode(_component_MetabolicScoreResult, {
          title: _ctx.$t('metabolicEvaluation.rest'),
          "metabolic-score": _ctx.fatOxidationResting
        }, null, 8, ["title", "metabolic-score"])
      ]),
      _: 1
    }, 8, ["title"])
  ]))
}