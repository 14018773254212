import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "score-bar-widget"
}
const _hoisted_2 = { class: "score-bar-widget__heading" }
const _hoisted_3 = { class: "score-bar-widget__title" }
const _hoisted_4 = { class: "score-bar-widget__scoring--belittle" }
const _hoisted_5 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DataBar = _resolveComponent("DataBar")!

  return (_ctx.metabolicScore)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("h5", _hoisted_3, _toDisplayString(_ctx.title), 1),
          _createElementVNode("span", {
            class: _normalizeClass(["score-bar-widget__scoring", _ctx.scoringUnitClasses])
          }, [
            _createTextVNode(_toDisplayString(_ctx.metabolicScore.value), 1),
            _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.measurementUnit), 1)
          ], 2)
        ]),
        _createVNode(_component_DataBar, { "metabolic-score": _ctx.metabolicScore }, null, 8, ["metabolic-score"])
      ]))
    : (_openBlock(), _createElementBlock("div", _hoisted_5))
}