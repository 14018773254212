import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "card" }
const _hoisted_2 = ["aria-label"]
const _hoisted_3 = { class: "p-grid p-fluid" }
const _hoisted_4 = { class: "p-field p-col-12" }
const _hoisted_5 = { for: "observations" }
const _hoisted_6 = {
  key: 0,
  class: "card-footer"
}
const _hoisted_7 = { class: "p-grid p-fluid" }
const _hoisted_8 = { class: "p-field p-col-12" }
const _hoisted_9 = { for: "actions" }
const _hoisted_10 = {
  key: 0,
  class: "card-footer"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_HeadingComponent = _resolveComponent("HeadingComponent")!
  const _component_Textarea = _resolveComponent("Textarea")!
  const _component_TabPanel = _resolveComponent("TabPanel")!
  const _component_TabView = _resolveComponent("TabView")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_HeadingComponent, {
      "sub-heading": true,
      divider: false,
      title: _ctx.$t('professionalValoration.title')
    }, {
      right: _withCtx(() => [
        _withDirectives((_openBlock(), _createBlock(_component_Button, {
          class: _normalizeClass(["p-button-rounded p-button-only-icon p-button-only-icon--dark", _ctx.editMode ? 'p-disabled' : '']),
          onClick: _ctx.onEditClicked
        }, {
          default: _withCtx(() => [
            _createElementVNode("i", {
              class: "icon-edit",
              "aria-label": _ctx.$t('common.edit'),
              "aria-hidden": "true"
            }, null, 8, _hoisted_2)
          ]),
          _: 1
        }, 8, ["class", "onClick"])), [
          [
            _directive_tooltip,
            _ctx.$t('common.edit'),
            void 0,
            { bottom: true }
          ]
        ])
      ]),
      _: 1
    }, 8, ["title"]),
    _createVNode(_component_TabView, {
      "active-index": _ctx.activeIndex,
      "onUpdate:activeIndex": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.activeIndex) = $event))
    }, {
      default: _withCtx(() => [
        _createVNode(_component_TabPanel, {
          header: _ctx.$t('professionalValoration.observationsTabTitle'),
          disabled: _ctx.editMode
        }, {
          default: _withCtx(() => [
            _createElementVNode("form", null, [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, [
                  _createElementVNode("label", _hoisted_5, _toDisplayString(_ctx.$t('professionalValoration.observationsTabMessage')), 1),
                  _createElementVNode("span", {
                    class: _normalizeClass(["hint", !_ctx.editMode && !_ctx.observations ? '' : 'hidden'])
                  }, _toDisplayString(_ctx.$t('common.clickEdit')), 3),
                  _createVNode(_component_Textarea, {
                    id: "observations",
                    modelValue: _ctx.observations,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.observations) = $event)),
                    rows: "5",
                    "auto-resize": "true",
                    class: _normalizeClass(_ctx.editMode ? '' : 'no-editable')
                  }, null, 8, ["modelValue", "class"])
                ])
              ])
            ]),
            (_ctx.editMode)
              ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                  _createVNode(_component_Button, {
                    class: "p-button-secondary p-button-medium",
                    label: _ctx.$t('common.cancel'),
                    onClick: _ctx.onCancelObservations
                  }, null, 8, ["label", "onClick"]),
                  _createVNode(_component_Button, {
                    class: "p-button p-button-info p-button-medium",
                    label: _ctx.$t('common.save'),
                    loading: _ctx.waitSubmit,
                    onClick: _ctx.onSubmitObservations
                  }, null, 8, ["label", "loading", "onClick"])
                ]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["header", "disabled"]),
        _createVNode(_component_TabPanel, {
          header: _ctx.$t('professionalValoration.actionsToBeTakenTabTitle'),
          disabled: _ctx.editMode
        }, {
          default: _withCtx(() => [
            _createElementVNode("form", null, [
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("div", _hoisted_8, [
                  _createElementVNode("label", _hoisted_9, _toDisplayString(_ctx.$t('professionalValoration.actionsToBeTakenTabMessage')), 1),
                  _createElementVNode("span", {
                    class: _normalizeClass(["hint", !_ctx.editMode && !_ctx.actionsToBeTaken ? '' : 'hidden'])
                  }, _toDisplayString(_ctx.$t('common.clickEdit')), 3),
                  _createVNode(_component_Textarea, {
                    id: "actions",
                    modelValue: _ctx.actionsToBeTaken,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.actionsToBeTaken) = $event)),
                    rows: "5",
                    "auto-resize": "true",
                    class: _normalizeClass(_ctx.editMode ? '' : 'no-editable')
                  }, null, 8, ["modelValue", "class"])
                ])
              ])
            ]),
            (_ctx.editMode)
              ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                  _createVNode(_component_Button, {
                    class: "p-button-secondary p-button-medium",
                    label: _ctx.$t('common.cancel'),
                    onClick: _ctx.onCancelActions
                  }, null, 8, ["label", "onClick"]),
                  _createVNode(_component_Button, {
                    class: "p-button p-button-info p-button-medium",
                    label: _ctx.$t('common.save'),
                    loading: _ctx.waitSubmit,
                    onClick: _ctx.onSubmitActionsToBeTaken
                  }, null, 8, ["label", "loading", "onClick"])
                ]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["header", "disabled"])
      ]),
      _: 1
    }, 8, ["active-index"])
  ]))
}