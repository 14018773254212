import { Score } from './Score';

enum ChartType {
  CardiometabolicFootprint = 'CARDIOMETABOLIC_FOOTPRINT',
  CarbsPhase = 'CARBS_PHASE',
  EeCarbsFatHrPerTime = 'EE_CARBS_FAT_HR_PER_TIME',
  CarbsDonuts = 'CARBS_DONUTS',
  Human = 'HUMAN',
  CGM_DAILY = 'CGM_DAILY',
  CGM_SCORE_PER_DAY = 'CGM_SCORE_PER_DAY',
  CGM_HEAT_MAP = 'CGM_HEAT_MAP',
  CGM_CANDLESTICK = 'CGM_CANDLESTICK',

  HEART_RATE = 'HEART_RATE',
}

enum ResumeType {
  Rer = 'RER',
  HeartRate = 'HEART_RATE',
  BreathingFrequency = 'BREATHING_FREQUENCY',
  KcalHour = 'KCAL_HOUR',
  KcalDay = 'KCAL_DAY',
  Carbs = 'CARBS',
  Fat = 'FAT',
  CarbsPercentage = 'CARBS_PERCENTAGE',
  FatPercentage = 'FAT_PERCENTAGE',
}

enum SerieName {
  High = 'high',
  Low = 'low',
  Open = 'open',
  Close = 'close',
  Median = 'median',
}

interface Serie {
  name: string;
  data: number[];
  xaxis: string[];
}

interface Chart {
  type: ChartType;
  series: Serie[];
}

export default interface Statistics {
  charts: Chart[];
  scores: Score[];
  resume: Array<Record<string, unknown>>;
}

export { ChartType, ResumeType, Serie, Chart, Statistics, SerieName };
