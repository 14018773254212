import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, withCtx as _withCtx, createBlock as _createBlock, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "p-grid p-fluid" }
const _hoisted_2 = { class: "p-field p-col" }
const _hoisted_3 = { for: "sex" }
const _hoisted_4 = {
  key: 0,
  class: "p-invalid"
}
const _hoisted_5 = { class: "p-field p-col" }
const _hoisted_6 = { for: "age" }
const _hoisted_7 = {
  key: 0,
  class: "p-invalid"
}
const _hoisted_8 = { class: "p-field p-col" }
const _hoisted_9 = { for: "weight" }
const _hoisted_10 = {
  key: 0,
  class: "p-invalid"
}
const _hoisted_11 = { class: "p-field p-col" }
const _hoisted_12 = { for: "height" }
const _hoisted_13 = {
  key: 0,
  class: "p-invalid"
}
const _hoisted_14 = { class: "p-field p-col" }
const _hoisted_15 = { for: "fats" }
const _hoisted_16 = {
  key: 0,
  class: "p-invalid"
}
const _hoisted_17 = { class: "p-grid p-fluid" }
const _hoisted_18 = { class: "p-field p-col" }
const _hoisted_19 = { for: "activityLevel" }
const _hoisted_20 = { class: "p-field p-col" }
const _hoisted_21 = { for: "smoke" }
const _hoisted_22 = { class: "p-field p-col" }
const _hoisted_23 = { for: "alcohol" }
const _hoisted_24 = { class: "p-field p-col" }
const _hoisted_25 = { for: "cafe" }
const _hoisted_26 = { class: "p-grid p-fluid" }
const _hoisted_27 = { class: "p-field p-col" }
const _hoisted_28 = { for: "dietType" }
const _hoisted_29 = { class: "p-field p-col" }
const _hoisted_30 = { for: "medicines" }
const _hoisted_31 = { class: "p-grid p-fluid" }
const _hoisted_32 = {
  key: 0,
  class: "p-field p-col-12"
}
const _hoisted_33 = { class: "upload-container" }
const _hoisted_34 = {
  key: 0,
  class: "p-invalid"
}
const _hoisted_35 = {
  key: 1,
  class: "p-invalid"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_InputNumber = _resolveComponent("InputNumber")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_FileUpload = _resolveComponent("FileUpload")!
  const _component_Button = _resolveComponent("Button")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock("form", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("label", _hoisted_3, _toDisplayString(_ctx.$t('patient.sex')) + " *", 1),
        _createVNode(_component_Dropdown, {
          id: "sex",
          modelValue: _ctx.mutableMedicalTest.gender,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.mutableMedicalTest.gender) = $event)),
          placeholder: "Selecciona",
          options: _ctx.genderOptions,
          "option-label": "label",
          "option-value": "value",
          class: _normalizeClass(["p-disabled", { 'no-editable': !_ctx.editMode, 'p-invalid': _ctx.submitIntent && !_ctx.mutableMedicalTest.gender }])
        }, null, 8, ["modelValue", "options", "class"]),
        (_ctx.submitIntent && !_ctx.mutableMedicalTest.gender)
          ? (_openBlock(), _createElementBlock("small", _hoisted_4, _toDisplayString(_ctx.$t('common.requiredField')), 1))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("label", _hoisted_6, _toDisplayString(_ctx.$t('person.age')) + " *", 1),
        _createVNode(_component_InputNumber, {
          id: "age",
          modelValue: _ctx.patientAge,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.patientAge) = $event)),
          class: _normalizeClass(["p-disabled", { 'no-editable': !_ctx.editMode, 'p-invalid': _ctx.submitIntent && !_ctx.patientAge }])
        }, null, 8, ["modelValue", "class"]),
        (_ctx.submitIntent && !_ctx.patientAge)
          ? (_openBlock(), _createElementBlock("small", _hoisted_7, _toDisplayString(_ctx.$t('common.requiredField')), 1))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_8, [
        _createElementVNode("label", _hoisted_9, _toDisplayString(_ctx.$t('patient.currentWeight')) + " *", 1),
        _createVNode(_component_InputNumber, {
          id: "weight",
          modelValue: _ctx.mutableMedicalTest.weight,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.mutableMedicalTest.weight) = $event)),
          suffix: " kg",
          placeholder: "kg",
          class: _normalizeClass({ 'no-editable': !_ctx.editMode, 'p-invalid': _ctx.submitIntent && !_ctx.mutableMedicalTest.weight }),
          "min-fraction-digits": 2,
          "max-fraction-digits": 2
        }, null, 8, ["modelValue", "class"]),
        (_ctx.submitIntent && !_ctx.mutableMedicalTest.weight)
          ? (_openBlock(), _createElementBlock("small", _hoisted_10, _toDisplayString(_ctx.$t('common.requiredField')), 1))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_11, [
        _createElementVNode("label", _hoisted_12, _toDisplayString(_ctx.$t('patient.height')) + " *", 1),
        _createVNode(_component_InputNumber, {
          id: "height",
          modelValue: _ctx.mutableMedicalTest.height,
          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.mutableMedicalTest.height) = $event)),
          suffix: " cm",
          placeholder: "cm",
          class: _normalizeClass({ 'no-editable': !_ctx.editMode, 'p-invalid': _ctx.submitIntent && !_ctx.mutableMedicalTest.height })
        }, null, 8, ["modelValue", "class"]),
        (_ctx.submitIntent && !_ctx.mutableMedicalTest.height)
          ? (_openBlock(), _createElementBlock("small", _hoisted_13, _toDisplayString(_ctx.$t('common.requiredField')), 1))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_14, [
        _createElementVNode("label", _hoisted_15, _toDisplayString(_ctx.$t('patient.fatPercentage')) + " *", 1),
        _createVNode(_component_InputNumber, {
          id: "fats",
          modelValue: _ctx.mutableMedicalTest.fatPercentage,
          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.mutableMedicalTest.fatPercentage) = $event)),
          placeholder: "",
          "min-fraction-digits": 2,
          "max-fraction-digits": 2,
          class: _normalizeClass({ 'no-editable': !_ctx.editMode, 'p-invalid': _ctx.submitIntent && !_ctx.mutableMedicalTest.fatPercentage })
        }, null, 8, ["modelValue", "class"]),
        (_ctx.submitIntent && !_ctx.mutableMedicalTest.fatPercentage)
          ? (_openBlock(), _createElementBlock("small", _hoisted_16, _toDisplayString(_ctx.$t('common.requiredField')), 1))
          : _createCommentVNode("", true)
      ])
    ]),
    _createElementVNode("div", _hoisted_17, [
      _createElementVNode("div", _hoisted_18, [
        _createElementVNode("label", _hoisted_19, _toDisplayString(_ctx.$t('patient.activityLevel')), 1),
        _createVNode(_component_Dropdown, {
          id: "activityLevel",
          modelValue: _ctx.mutableMedicalTest.activityLevel,
          "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.mutableMedicalTest.activityLevel) = $event)),
          placeholder: "Selecciona",
          options: _ctx.activityLevelOptions,
          "option-label": "label",
          "option-value": "value",
          class: _normalizeClass({ 'no-editable': !_ctx.editMode })
        }, null, 8, ["modelValue", "options", "class"])
      ]),
      _createElementVNode("div", _hoisted_20, [
        _createElementVNode("label", _hoisted_21, _toDisplayString(_ctx.$t('patient.smoke')), 1),
        _createVNode(_component_Dropdown, {
          id: "smoke",
          modelValue: _ctx.mutableMedicalTest.smoke,
          "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.mutableMedicalTest.smoke) = $event)),
          placeholder: "Selecciona",
          options: _ctx.smokeOptions,
          "option-label": "label",
          "option-value": "value",
          class: _normalizeClass({ 'no-editable': !_ctx.editMode })
        }, null, 8, ["modelValue", "options", "class"])
      ]),
      _createElementVNode("div", _hoisted_22, [
        _createElementVNode("label", _hoisted_23, _toDisplayString(_ctx.$t('patient.alcohol')), 1),
        _createVNode(_component_Dropdown, {
          id: "alcohol",
          modelValue: _ctx.mutableMedicalTest.alcohol,
          "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.mutableMedicalTest.alcohol) = $event)),
          placeholder: "Selecciona",
          options: _ctx.alcoholOptions,
          "option-label": "label",
          "option-value": "value",
          class: _normalizeClass({ 'no-editable': !_ctx.editMode })
        }, null, 8, ["modelValue", "options", "class"])
      ]),
      _createElementVNode("div", _hoisted_24, [
        _createElementVNode("label", _hoisted_25, _toDisplayString(_ctx.$t('patient.cafe')), 1),
        _createVNode(_component_Dropdown, {
          id: "cafe",
          modelValue: _ctx.mutableMedicalTest.cafeConsumption,
          "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.mutableMedicalTest.cafeConsumption) = $event)),
          placeholder: "Selecciona",
          options: _ctx.cafeOptions,
          "option-label": "label",
          "option-value": "value",
          class: _normalizeClass({ 'no-editable': !_ctx.editMode })
        }, null, 8, ["modelValue", "options", "class"])
      ])
    ]),
    _createElementVNode("div", _hoisted_26, [
      _createElementVNode("div", _hoisted_27, [
        _createElementVNode("label", _hoisted_28, _toDisplayString(_ctx.$t('patient.dietType')), 1),
        _createVNode(_component_Dropdown, {
          id: "dietType",
          modelValue: _ctx.mutableMedicalTest.dietType,
          "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.mutableMedicalTest.dietType) = $event)),
          placeholder: "Selecciona",
          options: _ctx.dietTypeOptions,
          "option-label": "label",
          "option-value": "value",
          class: _normalizeClass({ 'no-editable': !_ctx.editMode })
        }, null, 8, ["modelValue", "options", "class"])
      ]),
      _createElementVNode("div", _hoisted_29, [
        _createElementVNode("label", _hoisted_30, _toDisplayString(_ctx.$t('patient.medicines')), 1),
        _createVNode(_component_InputText, {
          id: "medicines",
          modelValue: _ctx.mutableMedicalTest.medicines,
          "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.mutableMedicalTest.medicines) = $event)),
          class: _normalizeClass({ 'no-editable': !_ctx.editMode })
        }, null, 8, ["modelValue", "class"])
      ])
    ]),
    _createElementVNode("div", _hoisted_31, [
      (_ctx.editMode)
        ? (_openBlock(), _createElementBlock("div", _hoisted_32, [
            _createElementVNode("label", null, _toDisplayString(_ctx.$t('statistics.docWithExtension', { extension: '.csv' })), 1),
            _createElementVNode("div", _hoisted_33, [
              _createVNode(_component_FileUpload, {
                ref: "fileUploadRef",
                mode: "basic",
                name: "file[]",
                accept: ".csv",
                "choose-label": _ctx.$t('statistics.uploadDoc', { extension: '.csv' }),
                class: _normalizeClass(["p-button-link", { 'p-disabled': _ctx.selectedDocument != undefined }]),
                onSelect: _ctx.onDocumentSelected
              }, null, 8, ["choose-label", "class", "onSelect"]),
              _withDirectives((_openBlock(), _createBlock(_component_Button, {
                id: "remove-file",
                class: _normalizeClass(["p-button-rounded p-button-only-icon p-button-delete", { show: _ctx.selectedDocument != undefined }]),
                onClick: _ctx.onClearFile
              }, {
                default: _withCtx(() => _cache[11] || (_cache[11] = [
                  _createElementVNode("i", {
                    class: "icon-close_big",
                    "aria-hidden": "true"
                  }, null, -1)
                ])),
                _: 1
              }, 8, ["class", "onClick"])), [
                [
                  _directive_tooltip,
                  _ctx.$t('common.delete'),
                  void 0,
                  { bottom: true }
                ]
              ])
            ]),
            (_ctx.submitIntent && !_ctx.selectedDocument)
              ? (_openBlock(), _createElementBlock("small", _hoisted_34, _toDisplayString(_ctx.$t('common.requiredField')), 1))
              : _createCommentVNode("", true),
            (_ctx.selectedDocument && !_ctx.fileMatchExtension)
              ? (_openBlock(), _createElementBlock("small", _hoisted_35, _toDisplayString(_ctx.$t('statistics.wrongExtension', { extension: '.csv' })), 1))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}