
import { ChartType, Statistics } from '@/models/Statistics';
import { defineComponent, ref, computed, toRefs, watchEffect } from 'vue';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  props: {
    statistics: {
      type: Object as () => Statistics,
      required: true,
    },
  },

  setup(props) {
    const chartOptions = ref();
    const { t } = useI18n();

    const { statistics } = toRefs(props);
    const heartRateChart = statistics.value.charts.find((chart) => chart.type === ChartType.EeCarbsFatHrPerTime);

    const hearthRateSeries = computed(() => {
      return heartRateChart?.series.map((serie: any) => {
        return {
          name: t(`heartRateCard.series.${serie.name}`),
          data: serie.data,
        };
      });
    });

    const hearthRateXaxis = computed(() => {
      const hearthRateXaxisInner: any[] = [];
      heartRateChart?.series[0].xaxis.forEach((x) => {
        const minutes = parseInt(x) / 60;
        hearthRateXaxisInner.push(String(minutes) + ' ' + t('heartRateCard.units.minutes'));
      });
      return hearthRateXaxisInner;
    });

    const hearthRateMaxValueRounded = computed(() => {
      const kCalHourSerie = heartRateChart?.series.find((serie: any) => serie.name === 'EE_KCAL_HOUR(kcal/hour)');
      if (kCalHourSerie) {
        const maxValue: number = Math.max(...kCalHourSerie.data);
        return Math.ceil(maxValue / 100) * 100;
      }
      return 0;
    });

    /**
     * Workaround to update the chartOptions
     */
    const renderLabels = function (labels: string[]) {
      chartOptions.value = {
        chart: {
          type: 'line',
          toolbar: {
            show: false,
            tools: {
              download: false,
            },
          },
          zoom: {
            enabled: false,
          },
        },
        colors: ['#BA97EA', '#FFD15B', '#118EC5', '#FF0099'],
        dataLabels: {
          enabled: true,
          enabledOnSeries: [3],
          style: {
            fontSize: '12px',
            fontFamily: 'Rubik, Arial, sans-serif',
          },
        },
        stroke: {
          curve: 'smooth',
        },
        xaxis: {
          categories: labels,
          title: {
            text: t('heartRateCard.units.time'),
            style: {
              color: '#556677',
              fontWeight: 700,
              fontFamily: 'Rubik, Arial, sans-serif',
            },
          },
        },
        yaxis: [
          {
            seriesName: 'kcal',
            max: hearthRateMaxValueRounded.value,
            labels: {
              style: {
                colors: ['#556677'],
                fontFamily: 'Rubik, Arial, sans-serif',
              },
            },
            title: {
              text: t('heartRateCard.units.kcal'),
              style: {
                color: '#556677',
                fontWeight: 700,
                fontFamily: 'Rubik, Arial, sans-serif',
              },
            },
          },
          {
            seriesName: 'kcal',
            max: hearthRateMaxValueRounded.value,
            show: false,
          },
          {
            seriesName: 'kcal',
            max: hearthRateMaxValueRounded.value,
            show: false,
          },
          {
            seriesName: 'bpm',
            opposite: true,
            labels: {
              style: {
                colors: ['#556677'],
                fontFamily: 'Rubik, Arial, sans-serif',
              },
            },
            title: {
              text: t('heartRateCard.units.ppm'),
              style: {
                color: '#556677',
                fontWeight: 700,
                fontFamily: 'Rubik, Arial, sans-serif',
              },
            },
          },
        ],
        legend: {
          labels: {
            colors: '#556677',
            fontFamily: 'Rubik, Arial, sans-serif',
          },
          markers: {
            width: 6,
            height: 6,
          },
          itemMargin: {
            horizontal: 8,
            vertical: 24,
          },
        },
      };
    };

    watchEffect(() => renderLabels(hearthRateXaxis.value));

    return { renderLabels, chartOptions, hearthRateSeries };
  },
});
