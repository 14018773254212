
import { getMark, getEfficiencyColor } from '@/helpers/MetabolicScore.helper';
import { defineComponent } from '@vue/runtime-core';
import { computed } from 'vue';

export default defineComponent({
  props: {
    metabolicScoreValue: {
      type: Number as () => number | undefined,
      required: true,
    },
  },
  setup(props) {
    const mark = computed(() => {
      if (!props.metabolicScoreValue) {
        return 0;
      }
      return getMark(props.metabolicScoreValue);
    });

    const efficiencyColorClass = computed(() => {
      if (!props.metabolicScoreValue) {
        return '';
      }
      const color = getEfficiencyColor(props.metabolicScoreValue);
      return `score-efficiency__result--${color}`;
    });

    return { mark, efficiencyColorClass };
  },
});
