
import { defineComponent } from '@vue/runtime-core';

export default defineComponent({
  props: {
    title: {
      type: String as () => string,
      required: true,
    },
  },
});
