import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "score-widget" }
const _hoisted_2 = { class: "score-widget__title" }
const _hoisted_3 = { class: "score-widget__scores" }
const _hoisted_4 = { class: "score_widget__bar-widget" }
const _hoisted_5 = { class: "score_widget__bar-widget" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h4", _hoisted_2, _toDisplayString(_ctx.title), 1),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _renderSlot(_ctx.$slots, "first")
      ]),
      _createElementVNode("div", _hoisted_5, [
        _renderSlot(_ctx.$slots, "second")
      ])
    ])
  ]))
}