import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "content" }
const _hoisted_2 = {
  key: 0,
  class: "row"
}
const _hoisted_3 = { class: "c-measurement" }
const _hoisted_4 = { class: "c-measurement" }
const _hoisted_5 = { class: "c-measurement" }
const _hoisted_6 = { class: "c-measurement" }
const _hoisted_7 = { class: "chart" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_apexchart = _resolveComponent("apexchart")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.humanSeries)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _cache[4] || (_cache[4] = _createElementVNode("img", {
            src: "/../../assets/layout/images/patient-illustration.svg",
            alt: ""
          }, null, -1)),
          _createElementVNode("div", null, [
            _createElementVNode("div", _hoisted_3, [
              _cache[0] || (_cache[0] = _createElementVNode("i", {
                class: "icon-fire",
                "aria-hidden": "true"
              }, null, -1)),
              _createElementVNode("p", null, [
                _createTextVNode(_toDisplayString(_ctx.humanSeries['KCAL_DAY']) + " ", 1),
                _createElementVNode("span", null, _toDisplayString(_ctx.$t('restingMetabolismCard.units.kcalDay')), 1)
              ])
            ]),
            _createElementVNode("div", _hoisted_4, [
              _cache[1] || (_cache[1] = _createElementVNode("i", {
                class: "icon-heart",
                "aria-hidden": "true"
              }, null, -1)),
              _createElementVNode("p", null, [
                _createTextVNode(_toDisplayString(_ctx.humanSeries['HEART_RATE']) + " ", 1),
                _createElementVNode("span", null, _toDisplayString(_ctx.$t('restingMetabolismCard.units.hearthRate')), 1)
              ])
            ]),
            _createElementVNode("div", _hoisted_5, [
              _cache[2] || (_cache[2] = _createElementVNode("i", {
                class: "icon-lungs",
                "aria-hidden": "true"
              }, null, -1)),
              _createElementVNode("p", null, [
                _createTextVNode(_toDisplayString(_ctx.humanSeries['BREATHING_FREQUENCY']) + " ", 1),
                _createElementVNode("span", null, _toDisplayString(_ctx.$t('restingMetabolismCard.units.breathingFrecuency')), 1)
              ])
            ]),
            _createElementVNode("div", _hoisted_6, [
              _cache[3] || (_cache[3] = _createElementVNode("i", {
                class: "icon-rer",
                "aria-hidden": "true"
              }, null, -1)),
              _createElementVNode("p", null, [
                _createTextVNode(_toDisplayString(_ctx.humanSeries['RER']) + " ", 1),
                _createElementVNode("span", null, _toDisplayString(_ctx.$t('restingMetabolismCard.units.rer')), 1)
              ])
            ])
          ])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_7, [
      _createVNode(_component_apexchart, {
        options: _ctx.chartOptions,
        series: _ctx.restingSeries,
        height: 280
      }, null, 8, ["options", "series"])
    ])
  ]))
}