
import { ChartType, Statistics } from '@/models/Statistics';
import { defineComponent, ref, computed, watchEffect } from 'vue';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  props: {
    statistics: {
      type: Object as () => Statistics,
      required: true,
    },
  },

  setup(props) {
    const { t } = useI18n();
    const chartOptions = ref();

    const restingSeries = computed(() => {
      var chart = props.statistics.charts.find((c) => c.type === ChartType.CarbsDonuts);
      return chart ? props.statistics.charts.find((c) => c.type === ChartType.CarbsDonuts)?.series[0].data : [];
    });

    const restingLabels = computed(() => {
      var chart = props.statistics.charts.find((c) => c.type === ChartType.CarbsDonuts);
      return chart
        ? chart.series[0].xaxis.map(
            (val, index) => chart?.series[0].data[index] + ' ' + t(`restingMetabolismCard.series.${val}`),
          )
        : [];
    });

    const humanSeries = computed(() => {
      const innerHumanSeries: any = {};
      const chart = props.statistics.charts.find((c) => c.type === ChartType.Human);
      chart?.series.forEach((serie: any) => {
        innerHumanSeries[serie.name] = serie.data[0];
      });
      return innerHumanSeries;
    });

    /**
     * Workaround to update the chartOptions
     */
    const renderLabels = function (labels: string[]) {
      chartOptions.value = {
        labels: labels,
        chart: {
          type: 'donut',
        },
        colors: ['#BA97EA', '#FFD15B'],
        dataLabels: {
          enabled: true,
          style: {
            fontSize: '12px',
            fontFamily: 'Rubik, Arial, sans-serif',
            colors: ['#2E194B'],
          },
          dropShadow: {
            enabled: false,
          },
        },
        legend: {
          position: 'bottom',
          labels: {
            colors: '#556677',
            fontFamily: 'Rubik, Arial, sans-serif',
          },
          markers: {
            width: 6,
            height: 6,
          },
        },
      };
    };

    watchEffect(() => renderLabels(restingLabels.value));

    return { renderLabels, chartOptions, restingSeries, humanSeries };
  },
});
