
import { defineComponent } from '@vue/runtime-core';
import DataBar from '@/components/charts/MetabolicScoreDataBar.vue';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { Score, ScoreType } from '@/models/Score';
import { getColor } from '@/helpers/MetabolicScore.helper';

export default defineComponent({
  components: {
    DataBar,
  },
  props: {
    title: {
      type: String as () => string,
      required: true,
    },
    metabolicScore: {
      type: Object as () => Score | null,
      required: true,
    },
  },
  setup(props) {
    const t = useI18n().t;
    const measurementUnit = computed(() => {
      if (!props.metabolicScore) {
        return '';
      }
      switch (props.metabolicScore.type) {
        case ScoreType.TOTAL_ENERGY_ACTIVITY:
          return t('metabolicEvaluation.met');
        case ScoreType.TOTAL_ENERGY_RESTING:
          return t('metabolicEvaluation.kcalDay');
        case ScoreType.FAT_OXIDATION_ACTIVITY:
          return t('metabolicEvaluation.kcalMin');
        case ScoreType.FAT_OXIDATION_RESTING:
          return '%';
        case ScoreType.EFFICIENCY:
          return '';
        default:
          return '';
      }
    });

    const scoringUnitClasses = computed(() => {
      if (!props.metabolicScore) {
        return '';
      }
      const color = getColor(props.metabolicScore);
      const classes: { [s: string]: boolean } = {};
      classes[`score-bar-widget__unit--${color}`] = true;
      return classes;
    });
    return { measurementUnit, scoringUnitClasses };
  },
});
