import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "databar-wrapper" }
const _hoisted_2 = { class: "databar__scale" }
const _hoisted_3 = { class: "databar__scale-unit" }
const _hoisted_4 = { class: "databar__scale-unit" }
const _hoisted_5 = { class: "databar__scale-unit" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["databar", ['databar--' + _ctx.scoreScale]])
    }, null, 2),
    _createElementVNode("div", {
      class: "databar-pointer",
      style: _normalizeStyle({ left: _ctx.leftStyle, backgroundColor: _ctx.pointerColor })
    }, null, 4),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("span", _hoisted_3, "<" + _toDisplayString(_ctx.metabolicScore.optimalValueMin), 1),
      _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.metabolicScore.optimalValue), 1),
      _createElementVNode("span", _hoisted_5, ">" + _toDisplayString(_ctx.metabolicScore.optimalValueMax), 1)
    ])
  ]))
}