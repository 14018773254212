
import FileUpload from 'primevue/fileupload';
import { computed, defineComponent, onMounted, PropType, ref, toRefs } from 'vue';
import { medicalTestService } from '@/services/MedicalTestService';
import { useI18n } from 'vue-i18n';
import dateFormat from '@/helpers/DateFormat.helper';
import { measurementService } from '@/services/MeasurementService';
import { ActivityLevel, CafeConsumption, DietType, MedicalTest } from '@/models/MedicalTest';
import { PatientDto } from '@/models/Patient';
import { useToast } from 'primevue/usetoast';
import { Genders } from '@/models/Genders';
import { ServiceError } from '@/services/util/ServiceError';

export default defineComponent({
  components: { FileUpload },

  emits: ['form-submitted'],
  props: {
    medicalTest: {
      type: Object as PropType<MedicalTest>,
      required: true,
    },
    patient: {
      type: Object as PropType<PatientDto>,
      required: true,
    },
    editMode: {
      type: Boolean,
      default: () => {
        return true;
      },
    },
  },
  setup(props, { emit }) {
    const { t } = useI18n();
    const toast = useToast();
    const mutableMedicalTest = toRefs(props).medicalTest;
    const patientAge = computed(() =>
      props.patient && props.patient.birthdate ? dateFormat.calculateAge(props.patient.birthdate) : undefined,
    );
    const loading = ref(false);
    const submitIntent = ref(false);
    const selectedDocument = ref();
    const fileMatchExtension = computed(() => {
      return selectedDocument.value && selectedDocument.value.name.endsWith('.csv');
    });
    const fileUploadRef = ref();

    const genderOptions = ref([
      { label: t(`user.${Genders.Female}`), value: Genders.Female },
      { label: t(`user.${Genders.Male}`), value: Genders.Male },
    ]);

    const activityLevelOptions = ref([
      { label: t('activityLevel.sedentary'), value: ActivityLevel.Sedentary },
      { label: t('activityLevel.active'), value: ActivityLevel.Active },
      { label: t('activityLevel.veryActive'), value: ActivityLevel.VeryActive },
    ]);

    const smokeOptions = ref([
      { label: t('common.yes'), value: true },
      { label: t('common.no'), value: false },
    ]);

    const alcoholOptions = ref([
      { label: t('common.yes'), value: true },
      { label: t('common.no'), value: false },
    ]);

    const cafeOptions = ref([
      { label: t('cafeConsumption.no'), value: CafeConsumption.No },
      { label: t('cafeConsumption.oneOrTwoPerDay'), value: CafeConsumption.OneOrTwoPerDay },
      { label: t('cafeConsumption.threeOrMorePerDay'), value: CafeConsumption.ThreeOrMorePerDay },
    ]);

    const dietTypeOptions = ref([
      { label: t('dietType.no'), value: DietType.No },
      { label: t('dietType.keto'), value: DietType.Keto },
      { label: t('dietType.mediterranean'), value: DietType.Mediterranean },
      { label: t('dietType.vegan'), value: DietType.Vegan },
      { label: t('dietType.highCarb'), value: DietType.HighCarb },
      { label: t('dietType.paleo'), value: DietType.Paleo },
    ]);

    onMounted(async () => {});

    const submit = async () => {
      submitIntent.value = true;
      if (
        !props.patient ||
        !props.patient.birthdate ||
        !mutableMedicalTest.value ||
        !mutableMedicalTest.value.gender ||
        !mutableMedicalTest.value.fatPercentage ||
        !mutableMedicalTest.value.weight ||
        !mutableMedicalTest.value.height ||
        !selectedDocument.value ||
        !fileMatchExtension.value
      ) {
        return;
      }
      await medicalTestService.update(props.patient.id, mutableMedicalTest.value.id, mutableMedicalTest.value);
      const uploadResult = await uploadCsv();
      if (uploadResult) {
        emit('form-submitted');
      }
    };
    const onDocumentSelected = async (event: any) => {
      if (!event.files || event.files.length == 0) {
        return;
      }
      selectedDocument.value = event.files[0];
    };

    const onClearFile = () => {
      fileUploadRef.value?.clear();
      selectedDocument.value = undefined;
    };

    async function uploadCsv(): Promise<boolean> {
      let formData = new FormData();
      formData.append('file', selectedDocument.value);
      loading.value = true;
      const result = await measurementService.createFromCsv(props.patient.id, props.medicalTest.id, formData);
      loading.value = false;
      if (result) {
        if (result instanceof ServiceError) {
          toast.add({ severity: 'error', summary: t(`messages.error.${result.code}`), life: 3000 });
          return false;
        } else {
          toast.add({ severity: 'success', summary: `${t('messages.notifications.successUploadFile')}`, life: 3000 });
          return true;
        }
      } else {
        toast.add({
          severity: 'error',
          summary: `${t('messages.notifications.errorUploadFile')} ${t('messages.notifications.tryLater')}`,
          life: 3000,
        });
        return false;
      }
    }

    return {
      mutableMedicalTest,
      patientAge,
      loading,
      genderOptions,
      activityLevelOptions,
      smokeOptions,
      alcoholOptions,
      cafeOptions,
      dietTypeOptions,
      submitIntent,
      selectedDocument,
      fileUploadRef,
      fileMatchExtension,
      onDocumentSelected,
      onClearFile,
      submit,
    };
  },
});
