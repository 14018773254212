import { Score, ScoreType } from '@/models/Score';

type MetabolicScoreColor = 'red' | 'yellow' | 'green';
type MetabolicScoreMark = 'problematic' | 'good' | 'remarkable' | 'excellent';

const getColor: (m: Score) => MetabolicScoreColor = (metabolicScore: Score) => {
  const rangeStart = metabolicScore.optimalValueMin || 0;
  const rangeEnd = metabolicScore.optimalValueMax || 10;
  const percentageValue = metabolicScore.value
    ? ((metabolicScore.value - rangeStart) / (rangeEnd - rangeStart)) * 100
    : 0;

  if (metabolicScore.type === ScoreType.FAT_OXIDATION_RESTING) {
    return percentageValue < 50 ? 'red' : 'green';
  }

  if (percentageValue < 20) {
    return 'red';
  }
  if (percentageValue < 40) {
    return 'yellow';
  }
  if (percentageValue < 60) {
    return 'green';
  }
  if (percentageValue < 80) {
    return 'yellow';
  }
  if (
    percentageValue >= 80 &&
    (metabolicScore.type === ScoreType.TOTAL_ENERGY_RESTING || metabolicScore.type === ScoreType.FAT_OXIDATION_ACTIVITY)
  ) {
    return 'yellow';
  }

  return 'red';
};

const getEfficiencyColor: (s: number) => MetabolicScoreColor = (efficiency: number) => {
  return efficiency < 5 ? 'red' : 'green';
};

const getMark: (s: number) => MetabolicScoreMark = (scoreMark: number) => {
  if (scoreMark < 5) {
    return 'problematic';
  }
  if (scoreMark < 7) {
    return 'good';
  }
  if (scoreMark < 8.9) {
    return 'remarkable';
  }

  return 'excellent';
};

export { getColor, getMark, getEfficiencyColor, MetabolicScoreColor, MetabolicScoreMark };
