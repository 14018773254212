import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "l-container"
}
const _hoisted_2 = { class: "h3" }
const _hoisted_3 = { class: "p-grid--valuations" }
const _hoisted_4 = { class: "card" }
const _hoisted_5 = { class: "h3" }
const _hoisted_6 = { class: "p-grid--charts" }
const _hoisted_7 = { class: "card" }
const _hoisted_8 = { class: "card" }
const _hoisted_9 = { class: "card" }
const _hoisted_10 = { class: "card" }
const _hoisted_11 = {
  key: 2,
  class: "card empty-state"
}
const _hoisted_12 = { class: "h3" }
const _hoisted_13 = { class: "card-footer" }
const _hoisted_14 = {
  key: 0,
  class: "icon-doughnut_chart icon-spin"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_HeadingComponent = _resolveComponent("HeadingComponent")!
  const _component_LoadingComponent = _resolveComponent("LoadingComponent")!
  const _component_MetabolicScoresChart = _resolveComponent("MetabolicScoresChart")!
  const _component_ProfessionalValuationCard = _resolveComponent("ProfessionalValuationCard")!
  const _component_PhasesChart = _resolveComponent("PhasesChart")!
  const _component_RestingMetabolismChart = _resolveComponent("RestingMetabolismChart")!
  const _component_CardiometabolicChart = _resolveComponent("CardiometabolicChart")!
  const _component_HeartRateChart = _resolveComponent("HeartRateChart")!
  const _component_CalorimetryForm = _resolveComponent("CalorimetryForm")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.medicalTest)
      ? (_openBlock(), _createBlock(_component_HeadingComponent, {
          key: 0,
          title: _ctx.medicalTest.name,
          breadcrumb: _ctx.breadcrumb,
          "heading-icon": "icon-bar_chart_circle",
          details: _ctx.medicalTestDetails
        }, {
          right: _withCtx(() => [
            _createVNode(_component_Button, {
              class: _normalizeClass(["p-button-icon p-button-secondary", { 'p-button-outlined p-disabled': !_ctx.statistics }]),
              onClick: _ctx.onDownloadGuideClicked
            }, {
              default: _withCtx(() => [
                _cache[2] || (_cache[2] = _createElementVNode("i", {
                  class: "icon-download",
                  "aria-hidden": "true"
                }, null, -1)),
                _createElementVNode("span", null, _toDisplayString(_ctx.$t('common.downloadGuide')), 1)
              ]),
              _: 1
            }, 8, ["class", "onClick"]),
            _createVNode(_component_Button, {
              type: "button",
              class: _normalizeClass(["p-button-icon p-button-info", { 'p-button-outlined p-disabled': !_ctx.statistics, 'p-disabled': _ctx.waitingForReport }]),
              disabled: _ctx.waitingForReport,
              onClick: _ctx.onDownloadReportClicked
            }, {
              default: _withCtx(() => [
                _createElementVNode("i", {
                  "aria-hidden": "true",
                  class: _normalizeClass(_ctx.downloadIconClass)
                }, null, 2),
                _createElementVNode("span", null, _toDisplayString(_ctx.$t('common.downloadReport')), 1)
              ]),
              _: 1
            }, 8, ["class", "disabled", "onClick"])
          ]),
          _: 1
        }, 8, ["title", "breadcrumb", "details"]))
      : _createCommentVNode("", true),
    (_ctx.medicalTest)
      ? (_openBlock(), _createElementBlock("main", _hoisted_1, [
          (_ctx.loadingStatistics)
            ? (_openBlock(), _createBlock(_component_LoadingComponent, {
                key: 0,
                "spinner-color": "lavender-700"
              }))
            : _createCommentVNode("", true),
          (!_ctx.loadingStatistics && _ctx.statistics)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                _createElementVNode("h2", _hoisted_2, _toDisplayString(_ctx.$t('cgmDetail.valuations')), 1),
                _createElementVNode("div", _hoisted_3, [
                  _createElementVNode("div", _hoisted_4, [
                    _createVNode(_component_HeadingComponent, {
                      "sub-heading": true,
                      "sub-heading-level": 3,
                      divider: false,
                      title: _ctx.$t('metabolicEvaluation.title')
                    }, null, 8, ["title"]),
                    _createVNode(_component_MetabolicScoresChart, {
                      scores: _ctx.statistics.scores
                    }, null, 8, ["scores"])
                  ]),
                  _createVNode(_component_ProfessionalValuationCard, { medicalTest: _ctx.medicalTest }, null, 8, ["medicalTest"])
                ]),
                _createElementVNode("h2", _hoisted_5, _toDisplayString(_ctx.$t('cgmDetail.extract')), 1),
                _createElementVNode("div", _hoisted_6, [
                  _createElementVNode("div", _hoisted_7, [
                    _createVNode(_component_HeadingComponent, {
                      "sub-heading": true,
                      divider: false,
                      title: _ctx.$t('phasesCard.title')
                    }, null, 8, ["title"]),
                    _createVNode(_component_PhasesChart, { statistics: _ctx.statistics }, null, 8, ["statistics"])
                  ]),
                  _createElementVNode("div", _hoisted_8, [
                    _createVNode(_component_HeadingComponent, {
                      "sub-heading": true,
                      divider: false,
                      title: _ctx.$t('restingMetabolismCard.title')
                    }, null, 8, ["title"]),
                    _createVNode(_component_RestingMetabolismChart, { statistics: _ctx.statistics }, null, 8, ["statistics"])
                  ]),
                  _createElementVNode("div", _hoisted_9, [
                    _createVNode(_component_HeadingComponent, {
                      "sub-heading": true,
                      divider: false,
                      title: _ctx.$t('cardiometabolicCard.title')
                    }, null, 8, ["title"]),
                    _createVNode(_component_CardiometabolicChart, { statistics: _ctx.statistics }, null, 8, ["statistics"])
                  ]),
                  _createElementVNode("div", _hoisted_10, [
                    _createVNode(_component_HeadingComponent, {
                      "sub-heading": true,
                      divider: false,
                      title: _ctx.$t('heartRateCard.title')
                    }, null, 8, ["title"]),
                    _createVNode(_component_HeartRateChart, { statistics: _ctx.statistics }, null, 8, ["statistics"])
                  ])
                ])
              ], 64))
            : _createCommentVNode("", true),
          (!_ctx.loadingStatistics && !_ctx.statistics)
            ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                _cache[3] || (_cache[3] = _createElementVNode("i", {
                  class: "icon-error_outline",
                  "aria-hidden": "true"
                }, null, -1)),
                _createElementVNode("h2", _hoisted_12, _toDisplayString(_ctx.$t('statistics.newTitle')), 1),
                _createElementVNode("p", null, _toDisplayString(_ctx.$t('statistics.newMessage')), 1),
                (_ctx.patient)
                  ? (_openBlock(), _createBlock(_component_CalorimetryForm, {
                      key: 0,
                      ref: "CalorimetryFormRef",
                      patient: _ctx.patient,
                      medicalTest: _ctx.medicalTest,
                      onFormSubmitted: _ctx.onFormSubmitted
                    }, null, 8, ["patient", "medicalTest", "onFormSubmitted"]))
                  : _createCommentVNode("", true),
                _createElementVNode("div", _hoisted_13, [
                  _createVNode(_component_Button, {
                    class: "p-button-info p-button-medium",
                    disabled: _ctx.uploading,
                    loading: _ctx.uploading,
                    onClick: _ctx.onSubmit
                  }, {
                    default: _withCtx(() => [
                      (_ctx.uploading)
                        ? (_openBlock(), _createElementBlock("em", _hoisted_14))
                        : _createCommentVNode("", true),
                      _createElementVNode("span", null, _toDisplayString(_ctx.$t('common.send')), 1)
                    ]),
                    _: 1
                  }, 8, ["disabled", "loading", "onClick"])
                ])
              ]))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    _createVNode(_component_Dialog, {
      visible: _ctx.modal,
      "onUpdate:visible": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.modal) = $event)),
      modal: true,
      header: _ctx.$t('details.initialData')
    }, {
      footer: _withCtx(() => [
        _createVNode(_component_Button, {
          label: _ctx.$t('common.accept'),
          class: "p-button-medium",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.modal = false))
        }, null, 8, ["label"])
      ]),
      default: _withCtx(() => [
        (_ctx.patient && _ctx.medicalTest)
          ? (_openBlock(), _createBlock(_component_CalorimetryForm, {
              key: 0,
              patient: _ctx.patient,
              medicalTest: _ctx.medicalTest,
              "edit-mode": false
            }, null, 8, ["patient", "medicalTest"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["visible", "header"])
  ], 64))
}