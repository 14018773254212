
import { ChartType, Statistics } from '@/models/Statistics';
import { defineComponent, ref, computed, watchEffect } from 'vue';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  props: {
    statistics: {
      type: Object as () => Statistics,
      required: true,
    },
  },

  setup(props) {
    const chartOptions = ref();
    const { t } = useI18n();

    const phasesSeries = computed<{ name: string; data: number[] }[]>(() => {
      if (!props.statistics.charts) {
        return [];
      }
      const carbsPhaseChart = props.statistics?.charts.find((chart) => chart.type === ChartType.CarbsPhase);
      if (!carbsPhaseChart) return [];
      return carbsPhaseChart.series.map((serie) => {
        return {
          name: t(`phasesCard.series.${serie.name}`),
          data: serie.data,
        };
      });
    });
    const phasesXaxis = computed<string[]>(() => {
      if (!props.statistics.charts) {
        return [];
      }
      const carbsPhaseChart = props.statistics?.charts.find((chart) => chart.type === ChartType.CarbsPhase);
      if (!carbsPhaseChart) return [];
      return carbsPhaseChart?.series[0]?.xaxis?.map((val: any) => t(`phasesCard.series.${val}`));
    });
    const phasesResume = computed(() => {
      if (!props.statistics.resume) {
        return [];
      }
      return props.statistics?.resume.map((resumeItem) => {
        var resume = resumeItem;
        resume['TYPE'] = t(`phasesCard.series.${resumeItem.TYPE}`);
        return resume;
      });
    });

    /**
     * Workaround to update the chartOptions
     */
    const renderLabels = function (labels: string[]) {
      chartOptions.value = {
        chart: {
          type: 'bar',
          stacked: true,
          toolbar: {
            show: false,
            tools: {
              download: false,
            },
          },
          zoom: {
            enabled: false,
          },
        },
        plotOptions: {
          bar: {
            borderRadius: 8,
          },
        },
        fill: {
          colors: ['#BA97EA', '#FFD15B'],
        },
        dataLabels: {
          enabled: true,
          style: {
            fontSize: '12px',
            fontFamily: 'Rubik, Arial, sans-serif',
            colors: ['#2E194B'],
          },
        },
        stroke: {
          curve: 'smooth',
        },
        xaxis: {
          categories: labels,
          labels: {
            show: false,
          },
        },
        yaxis: {
          labels: {
            style: {
              colors: ['#556677'],
              fontFamily: 'Rubik, Arial, sans-serif',
            },
          },
          title: {
            text: t('phasesCard.units'),
            style: {
              color: '#556677',
              fontWeight: 700,
              fontFamily: 'Rubik, Arial, sans-serif',
            },
          },
        },
        legend: {
          show: false,
        },
      };
    };

    watchEffect(() => renderLabels(phasesXaxis.value));

    return { renderLabels, chartOptions, phasesSeries, phasesXaxis, phasesResume };
  },
});
