
import { ChartType, Statistics } from '@/models/Statistics';
import { defineComponent, ref, computed, Ref, watchEffect } from 'vue';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  props: {
    statistics: {
      type: Object as () => Statistics,
      required: true,
    },
  },
  setup(props) {
    const chartOptions = ref();
    const { t } = useI18n();

    const cardiometabolicFootprintChart = props.statistics.charts.find(
      (chart) => chart.type === ChartType.CardiometabolicFootprint,
    );
    const cardiometabolicSeries = computed(() => {
      const cardiometabolicSeriesInner: any[] = [];
      cardiometabolicFootprintChart?.series.forEach((serie) => {
        cardiometabolicSeriesInner.push({
          name: t(`cardiometabolicCard.series.${serie.name}`),
          data: serie.data,
        });
      });
      return cardiometabolicSeriesInner;
    });

    const cardiometabolicXaxis: Ref<string[]> = computed(() => {
      return cardiometabolicFootprintChart ? cardiometabolicFootprintChart.series[0].xaxis : [];
    });

    /**
     * Workaround to update the chartOptions
     */
    const renderLabels = function (labels: string[]) {
      chartOptions.value = {
        chart: {
          type: 'line',
          toolbar: {
            show: false,
            tools: {
              download: false,
            },
          },
          zoom: {
            enabled: false,
          },
        },
        colors: ['#BA97EA', '#FFD15B', '#118EC5'],
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: 'smooth',
        },
        xaxis: {
          categories: labels,
          labels: {
            style: {
              colors: ['#556677'],
            },
          },
          title: {
            text: t('cardiometabolicCard.units.ppm'),
            style: {
              color: '#556677',
              fontWeight: 700,
              fontFamily: 'Rubik, Arial, sans-serif',
            },
          },
        },
        yaxis: {
          labels: {
            style: {
              colors: ['#556677'],
            },
          },
          title: {
            text: t('cardiometabolicCard.units.kcal'),
            style: {
              color: '#556677',
              fontWeight: 700,
              fontFamily: 'Rubik, Arial, sans-serif',
            },
          },
        },
        legend: {
          labels: {
            colors: '#556677',
          },
          markers: {
            width: 6,
            height: 6,
          },
          itemMargin: {
            horizontal: 8,
            vertical: 24,
          },
        },
      };
    };

    watchEffect(() => renderLabels(cardiometabolicXaxis.value));

    return { renderLabels, chartOptions, cardiometabolicSeries, cardiometabolicXaxis };
  },
});
